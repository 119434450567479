
export default {
  data() {
    return {
      includeService: [
        "选择服务",
        "购车信息查询",
        "提车服务",
        "车辆外观配置校验",
        "提车前车辆保管",
        "交车服务",
        "协同办理购置税教授手续",
        "免费上牌",
      ],
      noIncludeService: ["车险", "购置税"],
    };
  },
  methods: {},
};
