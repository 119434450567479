
import { defineComponent } from "vue";
import numeral from "numeral";
export default defineComponent({
  props: {
    activeQuoteItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    grossFilter(value: any) {
      if (typeof value === "undefined" || value === "null") {
        return "--";
      }
      return numeral(value).format("0,0");
    },
  },
});
