<template>
  <div v-if="isReady" class="aaaaa">
    <div class="page-header-contanier">
      <div class="common-header">
        <div class="left-content" @click="onBack">
          <van-icon name="arrow-left" color="#fff" />
        </div>
        <div class="center-content">
          {{ pageTitle }}
        </div>
      </div>
    </div>
    <div v-if="quoteList.length == 0" class="page-main-content">
      <fail-result :servicePhone="servicePhone" :qrCode="qrCode" />
    </div>
    <div class="page-main-content" v-else>
      <van-tabs :active="active" z-index="0" @click="tabChange">
        <van-tab title="报价单"> </van-tab>
        <van-tab title="询价内容">
          <div>
            <inquiry-content :inquiryInfoClientVO="inquiryInfoClientVO" />
          </div>
        </van-tab>
      </van-tabs>
      <div v-show="active === 0">
        <!-- 轮播图 -->
        <div class="content">
          <div class="home">
            <!-- 选择的意向车型无报价 -->
            <div class="no-intention-result-sep"></div>
            <!-- <div class="no-intention-result" v-if="quoteList.length == 1">
              <span>{{ inquiryInfoClientVO.intendedModelName }}</span>
              暂无价格，系统为您推荐以下爆款车型价格暂无价格
            </div>
            <div class="home-text" v-else>以下为真车源报价</div> -->
            <!-- 选了车型且且有精准的结果 -->
            <div
              class="home-text"
              v-if="
                inquiryInfoClientVO.intendedModelName && quoteList.length == 1
              "
            >
              <span>以下是系统为您匹配的车源报价</span>
            </div>
            <!-- 选了车型有多条数据 -->
            <div
              class="no-intention-result"
              v-if="
                inquiryInfoClientVO.intendedModelName && quoteList.length > 1
              "
            >
              <span>{{ inquiryInfoClientVO.intendedModelName }}</span>
              暂无价格，系统为您推荐以下爆款车型价格
            </div>
            <!-- 选了预算且有结果 -->
            <div
              class="home-text"
              v-if="
                !inquiryInfoClientVO.intendedModelName && quoteList.length != 0
              "
            >
              以下是系统为您匹配的车源报价
            </div>
            <div class="swiper-container swiper1">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in quoteList"
                  :key="index"
                >
                  <div class="car-info">
                    <img
                      v-if="item.modelsAvatar"
                      class="car-info-img"
                      :src="item.modelsAvatar"
                    />
                    <img
                      v-else
                      class="car-info-img"
                      src="https://wechatdn.yiautos.com/inquiry_h5/v1/default_car.png"
                    />
                    <div class="car-info-right">
                      <div class="car-info-modelsName">
                        {{ item.seriesName }} | {{ item.modelsName }}
                      </div>
                      <div class="car-info-center">
                        <div class="car-info-purchaseCityName">
                          上牌城市：{{ item.salesStoreCityName }}
                        </div>
                        <div class="car-info-appearance">
                          {{ getAppearanceName(item.appearance) }}
                        </div>
                      </div>
                      <div class="car-info-footer">
                        <div class="car-info-indicativePrice-text">
                          指导价：<span class="car-info-indicativePrice-value"
                            >{{ item.indicativePrice }}万</span
                          >
                        </div>
                        <div class="car-info-indicativePrice-index">
                          {{ index + 1 + "/" + quoteList.length }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->

        <div :class="{ 'content-animation': showAnnimation }">
          <!-- 报价未过期 -->
          <div class="time-content">
            <div class="time-triangle"></div>
            <div v-if="activeQuoteItem.quoteStatus == 1" class="valid-time">
              报价有效期
              <img
                class="valid-img"
                src="https://wechatdn.yiautos.com/channel_query/v1/count_down.png"
              />{{ activeQuoteItem.validPeriod }}
              <div></div>
            </div>

            <div v-else class="overdue-time">报价已过期</div>
          </div>

          <div class="field">
            <div class="field-item">
              <div class="field-lable bold">车身总价</div>
              <div
                class="field-value"
                @click="showCarTotalPrice = !showCarTotalPrice"
              >
                {{ grossFilter(activeQuoteItem.carBodyPrice) }} 元
                <van-icon
                  name="arrow-down"
                  color="#A2ACB1"
                  :class="[
                    showCarTotalPrice ? 'icon-left icon-active' : 'icon-left',
                  ]"
                />
              </div>
            </div>
          </div>
          <div v-if="showCarTotalPrice">
            <div class="field">
              <div class="field-item">
                <div class="field-lable">裸车价</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.purchasePrice) }}元
                </div>
              </div>
            </div>
            <div class="field" v-if="activeQuoteItem.carPaintPrice">
              <div class="field-item">
                <div class="field-lable">车漆价格</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.carPaintPrice) }}元
                </div>
              </div>
            </div>
            <div class="field" v-if="activeQuoteItem.addConfigPrice">
              <div class="field-item">
                <div class="field-lable">
                  精品加装<span
                    class="detail-btn"
                    @click="addConfigPopupShow = true"
                    >明细</span
                  >
                </div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.addConfigPrice) }}元
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <div class="field-item">
              <div class="field-lable bold">配套费用</div>
              <div
                class="field-value"
                @click="showFullPeiTaoPrice = !showFullPeiTaoPrice"
              >
                {{ grossFilter(activeQuoteItem.fullPeiTaoPrice) }} 元
                <van-icon
                  name="arrow-down"
                  color="#A2ACB1"
                  :class="[
                    showFullPeiTaoPrice ? 'icon-left icon-active' : 'icon-left',
                  ]"
                />
              </div>
            </div>
          </div>
          <div v-if="showFullPeiTaoPrice">
            <div class="field">
              <div class="field-item">
                <div class="field-lable">购置税（预计）</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.purchaseTax) }}元
                </div>
              </div>
            </div>
            <!-- 消费税为0不显示 -->
            <div class="field" v-if="activeQuoteItem.consumptionTax">
              <div class="field-item">
                <div class="field-lable">消费税（预计）</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.consumptionTax) }}元
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-item">
                <div class="field-lable">
                  保险费用<span
                    class="detail-btn"
                    @click="insurancePopupShow = true"
                    >明细</span
                  >
                </div>
                <div class="field-value">
                  <div
                    class="discount"
                    v-if="activeQuoteItem.carInsuranceDiscount != 10"
                  >
                    优惠价
                  </div>
                  {{ grossFilter(activeQuoteItem.carInsurance) }}元
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-item">
                <div class="field-lable">运输费</div>
                <div class="field-value" v-if="activeQuoteItem.logisticsPrice">
                  {{ grossFilter(activeQuoteItem.logisticsPrice) }}元
                </div>
                <div class="field-value" v-else>该路线暂无运输费</div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-item">
              <div class="field-lable bold">
                服务费
                <span class="detail-btn" @click="servicePopupShow = true"
                  >服务内容</span
                >
              </div>
              <div class="field-value">
                {{ grossFilter(activeQuoteItem.fullServicePrice) }}元
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-item">
              <div class="field-lable bold">全款</div>
              <div class="field-value bold">
                {{ grossFilter(activeQuoteItem.fullTotalPrice) }}元
              </div>
            </div>
          </div>
          <!-- 服务内容 -->
          <van-popup
            :show="servicePopupShow"
            position="bottom"
            @close="servicePopupShow = false"
          >
            <div class="popup-header">
              <text class="popup-header-title">服务内容</text>
              <van-icon
                class="close-icon"
                name="cross"
                color="#A2ACB1"
                @click="servicePopupShow = false"
              />
            </div>
            <service-content />
          </van-popup>
          <!-- 保险费用 -->
          <van-popup
            :show="insurancePopupShow"
            position="bottom"
            @close="insurancePopupShow = false"
          >
            <div class="popup-header">
              <text class="popup-header-title">保险费用</text>
              <van-icon
                class="close-icon"
                name="cross"
                color="#A2ACB1"
                @click="insurancePopupShow = false"
              />
            </div>
            <insurance-content :activeQuoteItem="activeQuoteItem" />
          </van-popup>
          <!-- 精品加装 -->
          <van-popup
            :show="addConfigPopupShow"
            position="bottom"
            @close="addConfigPopupShow = false"
          >
            <div class="popup-header">
              <text class="popup-header-title">精品加装</text>
              <van-icon
                class="close-icon"
                name="cross"
                color="#A2ACB1"
                @click="addConfigPopupShow = false"
              />
            </div>
            <div>{{ activeQuoteItem.addConfigContent }}</div>
          </van-popup>

          <!-- 金融贷款计算器 -->
          <div v-if="activeQuoteItem.mortgageFinanceInstitution">
            <div class="field">
              <div class="field-item">
                <div class="field-lable bold">金融贷款计算器</div>
              </div>
            </div>
            <div class="field-special">
              <div class="field-special-content">
                <div class="field-special-item">首付比例</div>
                <div
                  class="form-button-pick"
                  style="margin-right: -0.52rem; padding-top: 0.16rem"
                >
                  <div
                    v-for="(item, index) in downPaymentOptions"
                    :key="item"
                    :class="
                      item.check
                        ? 'button-pick-item-active'
                        : 'button-pick-item'
                    "
                    @click="chooseDownPayment(index)"
                  >
                    <img
                      v-if="item.check"
                      class="checked-img"
                      src="https://wechatdn.yiautos.com/channel_query/v2/button-check.png"
                    />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="field-special">
              <div class="field-special-content">
                <div class="field-special-item">期数</div>
                <div
                  class="form-button-pick"
                  style="margin-right: -0.52rem; padding-top: 0.16rem"
                >
                  <div
                    v-for="(item, index) in periodsOptions"
                    :key="item"
                    :class="
                      item.check
                        ? 'button-pick-item-active'
                        : 'button-pick-item'
                    "
                    @click="choosePeriods(index)"
                  >
                    <img
                      v-if="item.check"
                      class="checked-img"
                      src="https://wechatdn.yiautos.com/channel_query/v2/button-check.png"
                    />
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field-rate-content">
              <div class="field-rate-content-header">
                <div class="field-lable">月利率</div>
                <div class="field-value">
                  {{ activeQuoteItem.mortgageMonthRate }}%
                </div>
              </div>
              <div class="month-rate-desc">
                (月利率因个人征信不同与实际有出入，以金融机构审核后为准)
              </div>
            </div>

            <div class="field">
              <div class="field-item">
                <div class="field-lable">首付</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.mortgageFirstPaymentPrice) }}元
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-item">
                <div class="field-lable">月供</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.mortgageMonthPrice) }}元
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-item">
                <div class="field-lable">总利息</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.mortgageTotalInterest) }}元
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-item">
                <div class="field-lable">金融手续费</div>
                <div class="field-value">
                  {{ grossFilter(activeQuoteItem.mortgageFinancePoundage) }}元
                </div>
              </div>
            </div>
          </div>

          <!-- 点赞：推荐车型移除点赞功能,没选车型不显示点赞 -->
          <likeDislike
            v-if="!displayStatus && showLike"
            ref="likeDislike"
            :inquiryInfoClientVO="inquiryInfoClientVO"
            :activeQuoteItem="activeQuoteItem"
            :showLike="showLike"
            @changeShowLike="changeShowLike"
          ></likeDislike>

          <!-- 上牌城市附近门店 -->
          <div v-else class="separate-content"></div>
          <store-list :cityCode="inquiryInfoClientVO.cityCode" />
          <!-- 专享福利 -->

          <div class="vip-welfare">专享福利</div>
          <div class="vip-center" @click="goToPdd">
            <img
              class="vip-img"
              src="https://websitedn.yiautos.com/channel_quotation/v4/pdd-link.jpeg"
            />
            <div class="pdd-link-footer">
              <div>点击前往领取专享福利</div>
              <van-icon name="arrow" />
            </div>
          </div>

          <div class="separate-content"></div>
          <!-- 联系方式 -->
          <div class="contact-container">
            <div class="contact-msg">
              已收到您的购车意向，服务顾问预计24小时内与你取得联系，报价有效期内价格真实有效
            </div>
            <contact :servicePhone="servicePhone" :qrCode="qrCode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InquiryContent from "./components/inquiry_content"; //报价内容
import ServiceContent from "./components/service_content"; //服务内容
import InsuranceContent from "./components/insurance_content"; //保险内容
import contact from "./components/contact"; //联系方式
import FailResult from "./components/fail_result"; //报价结果失败
import likeDislike from "./components/like-dislike.vue"; //点赞
import storeList from "./components/store_list.vue"; //所有门店
import store from "@/store/index.ts";
import { setChannelId, getChannelId } from "@/utils/StorageHelper.ts";
import { clamp } from "vant/lib/utils";
import * as service from "@/api/inquiry";
import numeral from "numeral";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.less";
export default {
  name: "quotation_result",
  components: {
    InquiryContent,
    ServiceContent,
    InsuranceContent,
    contact,
    FailResult,
    likeDislike,
    storeList,
  },
  data() {
    return {
      isReady: true,
      hasResult: true, //是否有报价结果
      active: 0,
      activeNames: ["1"],
      flexible: true,
      showCarTotalPrice: false, //车身总价是否展开
      showFullPeiTaoPrice: false, //配套费用是否展开
      servicePopupShow: false, //服务内容弹窗
      insurancePopupShow: false, //保险内容弹窗
      addConfigPopupShow: false, //精品家装弹
      downPayment: 0.2, //首付比例
      singleChoice: true,
      downPaymentOptions: [
        {
          code: 0.2,
          name: "20%",
          check: true,
        },
        {
          code: 0.3,
          name: "30%",
        },
        {
          code: 0.4,
          name: "40%",
        },
        {
          code: 0.5,
          name: "50%",
        },
      ],
      periods: 24, //贷款期数
      periodsOptions: [
        {
          code: 24,
          name: "24期",
          check: true,
        },
        {
          code: 36,
          name: "36期",
        },
        {
          code: 48,
          name: "48期",
        },
        {
          code: 60,
          name: "60期",
        },
      ],
      quoteList: [], //报价单数据
      inquiryInfoClientVO: {
        cityCode: "", //  上牌地点
        gender: "", //    #性别:0-未知-no,1-男-man,2-女-woman
        mobile: "", //   手机号
        nickName: "", //    昵称
        purchaseBudgets: "", //购车预算(0-10,10-15 字符串数组)
        purchaseTime: "", //#购车时间:0-3个月
        intendedModelName: "",
      }, //询价内容
      servicePhone: "", //客服电话
      qrCode: "", //上传二维码
      activeQuoteItem: {}, //当前显示的报价单数据
      quoteId: "", //报价单ID
      pageTitle: "车型报价", //页面标题
      displayStatus: true, //意向车型无报价时显示:true-显示,false-隐藏
      showAnnimation: false, //轮播动画
      setAnimation: 0,
      showLike: true,
    };
  },
  methods: {
    tabChange(e) {
      this.active = e;
    },
    onBack() {
      const id = this.$route.query.channelId;
      this.$router.push({
        path: "/inquiry-index",
        query: { channelId: id },
      });
    },
    changeSwiper(item) {
      this.showAnnimation = true;
      this.activeQuoteItem = this.quoteList[item];
      this.setAnimation = setTimeout(() => {
        this.showAnnimation = false;
      }, 1000);
    },
    // 切换期数
    choosePeriods(index) {
      const arr = this.periodsOptions;
      for (let i in arr) {
        if (index == i) {
          arr[i].check = true;
          this.periods = arr[i].code;
          this.calculate();
        } else {
          arr[i].check = false;
        }
      }
    },
    // 切换首付
    chooseDownPayment(index) {
      const arr = this.downPaymentOptions;
      for (let i in arr) {
        if (index == i) {
          arr[i].check = true;
          this.downPayment = arr[i].code;
          this.calculate();
        } else {
          arr[i].check = false;
        }
      }
    },
    // 计算首付 月供 总利息
    calculate() {
      const {
        purchasePrice,
        fullPeiTaoPrice,
        fullServicePrice,
        mortgageMonthRate,
        mortgageFinancePoundage,
        addConfigPrice,
        carPaintPrice,
        carBodyPrice,
      } = this.activeQuoteItem;
      const { downPayment, periods } = this;
      const LoanPrice = purchasePrice - purchasePrice * downPayment; //贷款总价
      const luoCarPrice = carBodyPrice - LoanPrice; //裸车首付
      const payment =
        purchasePrice * downPayment +
        fullPeiTaoPrice +
        fullServicePrice +
        mortgageFinancePoundage +
        addConfigPrice +
        carPaintPrice; //首付
      const monthSupply =
        LoanPrice / periods + LoanPrice * (mortgageMonthRate / 100); //月供
      const mortgageTotalInterest =
        LoanPrice * (mortgageMonthRate / 100) * periods; //总利息

      this.activeQuoteItem.mortgageFirstPaymentPrice = parseInt(payment);
      this.activeQuoteItem.mortgageMonthPrice = parseInt(monthSupply);
      this.activeQuoteItem.mortgageTotalInterest = parseInt(
        mortgageTotalInterest
      );
    },
    getAppearanceName(appearance) {
      if (!appearance) {
        return "--";
      }
      try {
        appearance = appearance.replace(/\\/g, "");
        const obj = JSON.parse(appearance);
        return obj.name || obj[0].name || "--";
      } catch (error) {
        return "--";
      }
    },
    grossFilter(value) {
      if (typeof value === "undefined" || value === "null") {
        return "--";
      }
      return numeral(value).format("0,0");
    },
    goToPdd() {
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "点击拼多多门店",
      });
      window.location.href =
        "https://mobile.yangkeduo.com/mall_page.html?refer_share_id=MqbSIHWgIRGQdE3CTKc08OTI6vzmVGyX&refer_share_channel=copy_link&refer_share_uid=&_wvx=10&mall_id=657293073&_wv=41729&share_uin=&_x_query=%E5%AE%9C%E4%B9%B0%E8%BD%A6&share_uid=&refer_share_uin=&msn=mgt4nqazaw3pm4eokgfxsery7u_axbuy&has_decoration=1&_x_org=1";
    },
    changeShowLike() {
      this.showLike = false;
    },
  },
  created() {
    const options = store.state.setQuoteResult;
    if (options.qrCode) {
      this.quoteList = options.quoteList || [];
      this.inquiryInfoClientVO = options.inquiryInfoClientVO;
      this.servicePhone = options.servicePhone;
      this.qrCode = options.qrCode;
      if (this.quoteList.length > 0) {
        this.activeQuoteItem = this.quoteList[0];
        if (this.activeQuoteItem.mortgageFinanceInstitution) {
          this.showCarTotalPrice = false;
          this.showFullPeiTaoPrice = false;
        } else {
          this.showCarTotalPrice = true;
          this.showFullPeiTaoPrice = true;
        }
      }
      this.displayStatus = options.displayStatus;
      //动态设置页面标题
      // 意向车型 : 车型报价 非意向车型：推荐车型报价
      if (this.displayStatus) {
        this.pageTitle = "车型报价";
      } else {
        this.pageTitle = "推荐车型报价";
      }
      this.isReady = true;
    } else {
      const id = this.$route.query.channelId;
      this.$router.push({
        path: "/inquiry-index",
        query: { channelId: id },
      });
    }
  },
  mounted() {
    let canLoop = true;
    if (this.quoteList.length > 2) {
      canLoop = true;
    } else {
      canLoop = false;
    }
    const that = this;
    new Swiper(".swiper1", {
      spaceBetween: 10,
      centeredSlides: true,
      loop: canLoop,
      slidesPerView: "auto",
      autoplay: false,
      on: {
        slideChange: function (item) {
          const activeIndex = item.realIndex;
          that.activeQuoteItem = that.quoteList[activeIndex];
          that.showAnnimation = true;
          that.setAnimation = setTimeout(() => {
            that.showAnnimation = false;
          }, 1000);
        },
      },
    });
  },
  unmounted() {
    clearTimeout(this.setAnimation);
    this.$store.commit("setCarModel", {});
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/common.less";

.aaaaa {
  background: #fff;
}
/deep/.van-tab {
  color: #fff;
  font-size: 0.32rem;
}
/deep/.van-tab--active {
  font-size: 0.32rem;
  color: #fff;
  font-weight: bold;
}
/deep/.van-tabs__line {
  background-color: #fff;
}
/deep/ .van-tabs__nav--line {
  background: #ffb500;
}
/deep/.van-tabs__scroll {
  background-color: #fefefe;
  border: 0.01rem solid #fff !important;
}
/deep/ .van-swipe__track {
  display: flex;
  height: 100%;
  align-items: center;
}
/deep/ .van-swipe {
  position: relative;
  cursor: grab;
  overflow: visible;
  width: 6.4rem;
  margin: 0 auto;
}
/deep/ .swiper-wrapper {
  align-items: center;
}
.page-header-contanier {
  position: fixed;
  top: 0;
  z-index: 100;
}
.common-header {
  width: 7.5rem;
  height: 0.88rem;
  background: #ffb500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .left-content {
    position: absolute;
    left: 0.3rem;
    color: #141414;
    font-size: 0.34rem;
  }
  .center-content {
    height: 0.34rem;
    font-family: PingFangSC-Medium;
    font-size: 0.34rem;
    color: #fff;
    text-align: center;
    line-height: 0.34rem;
  }
}
.page-main-content {
  padding-top: 0.8rem;
}
.home {
  background: #ffb500;
  .home-text {
    font-family: PingFangSC-Regular;
    font-size: 0.24rem;
    color: #fff;
    font-size: 0.28rem;
    line-height: 0 28rem;
    // margin-top: 0.2rem;
    padding-left: 0.2rem;
  }
  .no-intention-result {
    width: 7.5rem;
    padding: 0.15rem 0.3rem;
    background: #ffc433;
    color: #fff;
    font-size: 0.28rem;
    box-shadow: 0 0.04rem 0.16rem 0 rgba(0, 0, 0, 0.06);
  }
  .no-intention-result-sep {
    height: 0.3rem;
    background: #ffb500;
  }
  .swiper {
    height: 2.4rem !important;
  }
  .car-info {
    padding: 0.2rem;
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.16rem;
    display: flex;
    align-items: center;
    &-img {
      width: 1.6rem;
      height: 1.06rem;
      margin-right: 0.2rem;
    }
    .car-info-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 3.7rem;
    }
    &-modelsName {
      font-weight: bold;
      font-size: 0.28rem;
      color: #242b30;
      text-align: left;
      line-height: 0.42rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &-appearance {
      width: 1.8rem;
      font-family: PingFangSC-Regular;
      font-size: 0.24rem;
      color: #6a7377;
      text-align: right;
      line-height: 0.36rem;
      margin-left: 0.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-purchaseCityName {
      height: 0.36rem;
      font-family: PingFangSC-Regular;
      font-size: 0.24rem;
      color: #242b30;
      text-align: left;
      line-height: 0.36rem;
      width: 2.44rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-center {
      display: flex;
      justify-content: space-between;
      // margin-top: 0.2rem;
      // margin-bottom: 0.16rem;
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      // margin-top: 0.2rem;
    }
    &-indicativePrice {
      display: flex;
      &-text {
        height: 0.24rem;
        font-family: PingFangSC-Regular;
        font-size: 0.24rem;
        color: #242b30;
        text-align: right;
        line-height: 0.24rem;
      }
      &-index {
        width: 0.37rem;
        height: 0.24rem;
        font-family: PingFangSC-Regular;
        font-size: 0.24rem;
        color: #a2acb1;
        text-align: right;
        line-height: 0.24rem;
      }
      &-value {
        width: 0.24rem;
        height: 0 24rem;
        font-family: PingFangSC-Semibold;
        font-weight: bold;
        font-size: 0.24rem;
        color: #ff5858;
        text-align: right;
        line-height: 0.24rem;
      }
    }
  }
  .active-car {
    // background: #ff5858;
    padding: 0.3rem;
  }
}
.time-content {
  background: #ffb500;
  height: 0.7rem;
  position: relative;
  .time-triangle {
    width: 0;
    height: 0;
    border-left: 0.15rem solid transparent;
    border-right: 0.15rem solid transparent;
    border-bottom: 0.15rem solid #fff;
    position: absolute;
    top: -0.12rem;
    left: 3.5rem;
  }
  .valid-time {
    .flexible(row, center, center);
    width: 7.5rem;
    height: 0.71rem;
    background: #fff;
    color: #ff5858;
    font-size: 0.24rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 0.2rem;
  }
  .overdue-time {
    .flexible(row, center, center);
    width: 7.5rem;
    height: 0.71rem;
    background: #fff;
    color: #a2acb1;
    font-size: 0.24rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .valid-img {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.04rem;
    margin-left: 0.3rem;
    transform: translateY(0.005rem);
  }
}

.field {
  .flexible(row, center, center);
  &-item {
    width: 6.9rem;
    height: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &-item::after {
    content: " ";
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-bottom: 1px #eeeeee solid;
    transform-origin: left bottom;
  }
  &-lable {
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 0.28rem;
    color: #242b30;
    line-height: 0.28rem;
  }
  .detail-btn {
    height: 0.24rem;
    font-size: 0.24rem;
    color: #ffb500;
    text-align: center;
    line-height: 0.24rem;
    padding: 0.04rem 0.08rem;
    background: #fff7e5;
    margin-left: 0.2rem;
    font-weight: bold;
    border-radius: 0.06rem;
  }
  &-value {
    display: flex;
    height: 0.28rem;
    font-family: PingFangSC-Regular;
    font-size: 0.28rem;
    color: #242b30;
    line-height: 0 28rem;
  }
}
.field-rate-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-header {
    display: flex;
  }
}
.popup-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.2rem;
  position: relative;
  &-title {
    font-weight: bold;
    font-size: 0.36rem;
    color: #242b30;
  }
  .close-icon {
    position: absolute;
    right: 0.3rem;
    height: 50%;
    padding-top: 0.2rem;
    font-size: 0.4rem;
  }
}
.field-special {
  .flexible(row, center, center);
  overflow: hidden;
  position: relative;
  &-content {
    width: 6.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  &-item {
    font-family: PingFangSC-Regular;
    font-size: 0.24rem;
    color: #a2acb1;
  }
}
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  .contact-msg {
    width: 6.9rem;
    height: 0.84rem;
    font-family: PingFangSC-Regular;
    font-size: 0.28rem;
    color: #ffb500;
    text-align: left;
    line-height: 0.42rem;
  }
}
.bold {
  font-weight: bold;
}
.month-rate-desc {
  width: 7.5rem;
  height: 0.36rem;
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #a2acb1;
  text-align: left;
  line-height: 0.36rem;
  display: flex;
  justify-content: center;
}
.field-rate-content-header {
  display: flex;
  height: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.3rem;
}
.discount {
  .flexible(row, center, center);
  width: 0.76rem;
  height: 0.32rem;
  background: #fff2f2;
  border-radius: 0.04rem;
  margin-right: 0.2rem;
  font-size: 0.24rem;
  color: #ff5858;
}
.icon-left {
  margin-left: 0.2rem;
}
.icon-active {
  transform: rotate(180deg);
}
.logistic-name {
  width: 1.86rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.separate-content {
  height: 0.2rem;
  width: 7.5rem;
  background: #f4f6f8;
}
.form-button-pick {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  padding: 0.2rem;
  border-radius: 0.16rem;
}
.button-pick-item {
  .flexible(row, center, center);
  height: 0.68rem;
  height: 0.68rem;
  color: #242b30;
  border: 0.02rem solid #eeeeee;
  border-radius: 0.08rem;
  margin-bottom: 0.2rem;
  position: relative;
  padding: 0.3rem;
  margin-right: 0.2rem;
}
.button-pick-item-active {
  .flexible(row, center, center);
  height: 0.68rem;
  border: 0.02rem solid #ffb500;
  border-radius: 0.08rem;
  margin-bottom: 0.2rem;
  color: #ffb500;
  background: #fff7e5;
  position: relative;
  padding: 0.3rem;
  margin-right: 0.2rem;
}
.checked-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.4rem;
  height: 0.4rem;
}
.quoteList-content {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}
.vip-welfare {
  height: 0.32rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #242b30;
  text-align: left;
  line-height: 0.32rem;
  margin-bottom: 0.31rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
}
.vip-img {
  width: 6.9rem;
  height: 4.67rem;
}
.pdd-link-footer {
  position: absolute;
  bottom: 0.2rem;
  display: flex;
  justify-content: space-between;
  width: 6.15rem;
  align-items: center;
  color: #fff;
  font-weight: bold;
}
.store-list {
  display: flex;
  white-space: nowrap;
  overflow: scroll;
  .store-item {
    display: flex;
    width: 4.25rem;
    height: 1.76rem;
    padding: 0.24rem;
    background: #fff;
    border-radius: 0.1rem;
    margin-right: 0.4rem;
    box-shadow: 0 0.04rem 0.2rem 0 rgba(0, 0, 0, 0.08);
    .store-item-img {
      width: 1.28rem;
      height: 1.28rem;
      margin-right: 0.2rem;
    }
    .store-info {
      padding-top: 0.24rem;
      .store-name {
        font-size: 0.32rem;
        line-height: 0.32rem;
        color: #21252e;
        margin-bottom: 0.2rem;
      }
      .view-store {
        font-size: 0.28rem;
      }
    }
    .store-position {
      width: 0.2rem;
      height: 0.24rem;
      margin-right: 0.1rem;
    }
    .view-store {
      color: #ffb500;
      font-size: 0.28rem;
    }
  }
}
.center {
  .flexible(column,center,center);
}
.vip-center {
  .flexible(column,center,center);
  position: relative;
  margin-bottom: 0.3rem;
  .pdd-link-content {
    position: absolute;
    bottom: 0.2rem;
    display: flex;
    font-size: 0.24rem;
    font-weight: bold;
    color: #ffffff;
  }
}
.content-animation {
  animation-name: blink-2;
  animation-duration: 1s;
}
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.swiper-slide {
  width: 6rem;
  margin: 0.2rem 0rem;
  opacity: 0.6;
}
.swiper-slide-active {
  opacity: 1;
}
.swiper-slide-active .car-info {
  height: 2.23rem;
}
.swiper-slide-active .car-info-center {
  margin-top: 0.2rem;
  margin-bottom: 0.16rem;
}
</style>
