<template>
  <div v-if="storeList.length !== 0">
    <!-- 上牌城市附近门店 -->
    <div class="vip-welfare">上牌城市附近门店</div>
    <div class="store-list">
      <div class="store-item"
           v-for="item in storeList"
           :key="item">
        <img src="https://websitedn.yiautos.com/channel_quotation/v4/store.jpeg"
             class="store-item-img" />
        <div class="store-info">
          <div class="store-name">{{ item.storeName }}</div>
          <div @click="viewStoreInfo(item)">
            <img class="store-position"
                 src="https://websitedn.yiautos.com/channel_quotation/v4/position2.png" />
            <span class="view-store">查看门店</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 门店位置弹窗 -->
    <van-popup :show="storePopupShow"
               position="center"
               round
               @close="storePopupShow = false">
      <div class="popup-header">
        <text class="popup-header-title">查看门店</text>
        <van-icon class="close-icon"
                  name="cross"
                  color="#A2ACB1"
                  @click="closemap" />
      </div>
      <div>
        <store-map :storeInfo="storeInfo"
                   ref="storeMap" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import * as service from "@/api/inquiry";
import storeMap from "./store_map.vue"; //门店地图
export default {
  props: {
    cityCode: {
      type: String,
      required: true,
    },
  },
  components: {
    storeMap,
  },
  data () {
    return {
      storeList: [
        {
          address: "", //店铺地址'
          cityAreaName: "", //城市名称
          storeName: "", //店铺名称
          latitude: "", //维度'
          longitude: "", //经度
        },
      ],
      storeInfo: {},
      storePopupShow: false, //门店信息弹窗
    };
  },
  methods: {
    //所有门店
    async getStoreList () {
      const cityCode = this.cityCode;
      // const cityCode = "350200";
      const res = await service.getStoreList({ cityCode });
      this.storeList = res.data || [];
    },
    // 查看门店信息
    viewStoreInfo (data) {
      console.log("查看门店信息", data);
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "查看门店",
      });
      this.storeInfo = data;
      this.storePopupShow = true;
    },
    closemap () {
      this.storePopupShow = false;
    },
  },
  mounted () {
    this.getStoreList();
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/common.less";
.popup-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.2rem;
  position: relative;
  &-title {
    font-weight: bold;
    font-size: 0.36rem;
    color: #242b30;
  }
  .close-icon {
    position: absolute;
    right: 0.3rem;
    height: 50%;
    padding-top: 0.2rem;
    font-size: 0.4rem;
  }
}
.bold {
  font-weight: bold;
}
.store-list {
  display: flex;
  white-space: nowrap;
  overflow: auto;
  padding: 0.3rem;
  .store-item {
    display: flex;
    width: 4.25rem;
    height: 1.76rem;
    padding: 0.24rem;
    background: #fff;
    border-radius: 0.1rem;
    margin-right: 0.4rem;
    box-shadow: 0 0.04rem 0.2rem 0 rgba(0, 0, 0, 0.08);
    .store-item-img {
      width: 1.28rem;
      height: 1.28rem;
      margin-right: 0.2rem;
    }
    .store-info {
      padding-top: 0.24rem;
      .store-name {
        font-size: 0.32rem;
        line-height: 0.32rem;
        color: #21252e;
        margin-bottom: 0.2rem;
        width: 2.3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .view-store {
        font-size: 0.28rem;
      }
    }
    .store-position {
      width: 0.2rem;
      height: 0.24rem;
      margin-right: 0.1rem;
    }
    .view-store {
      color: #ffb500;
      font-size: 0.28rem;
    }
  }
}
.store-list::-webkit-scrollbar {
  display: none;
}
.center {
  .flexible(column,center,center);
}
.vip-welfare {
  height: 0.32rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #242b30;
  text-align: left;
  line-height: 0.32rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
}
</style>
