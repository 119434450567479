<template>
  <div class="store-map-container">
    <div id="container"></div>
    <div class="store-info">
      <div class="store-name">{{ storeInfo.storeName }}</div>
      <div class="store-address">门店地址：{{ storeInfo.address }}</div>
      <div class="store-contact">
        <div>联系门店：{{ storeInfo.storeManagerMobile }}</div>
        <div @click="makePhone">
          <img class="make-phone"
               src="
           https://websitedn.yiautos.com/channel_quotation/v4/phone.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  onUnmounted,
} from "vue";
import { useRoute } from "vue-router";
import { prop } from "vue-class-component";
export default defineComponent({
  props: {
    storeInfo: {
      type: Object,
      default: () => { },
    },
  },
  setup (props, context) {
    const mapObj = ref({});
    // 初始化地图
    function initMap () {
      //定义地图中心点坐标
      const { latitude, longitude, address } = props.storeInfo;
      const center = new TMap.LatLng(latitude, longitude);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      const map = new TMap.Map(document.getElementById("container"), {
        center: center, //设置地图中心点坐标
        zoom: 17.2, //设置地图缩放级别
        pitch: 30, //设置俯仰角
        rotation: 20, //设置地图旋转角度
        draggable: false, //是否支持拖拽移动地图，默认为true。
        scrollable: false, //是否支持鼠标滚轮缩放地图，默认为true。
      });
      mapObj.value = map;

      setMarker(latitude, longitude, address);
      setInfoWindow(latitude, longitude, address)
      // 去除logo及水印
      document.querySelector('canvas+div').lastChild.style.display = 'none';
    }
    // 设置地图中心
    function setCenter (latitude, longitude, address) {
      mapObj.value.setCenter(new TMap.LatLng(latitude, longitude));
      setMarker(latitude, longitude, address);
      setInfoWindow(latitude, longitude, address)
    }
    //设置点标记
    function setMarker (latitude, longitude, address) {
      const markerLayer = new TMap.MultiMarker({
        map: mapObj.value,
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 24,
            height: 24,
            src: "https://websitedn.yiautos.com/channel_quotation/v4/map-cover.png",
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1",
            styleId: "myStyle",
            position: new TMap.LatLng(latitude, longitude),
            properties: {
              title: "marker1",
            },
          },
        ],
      });
    }
    //设置信息窗口
    function setInfoWindow (latitude, longitude, address) {
      const center = new TMap.LatLng(latitude, longitude);
      var infowindow = new TMap.InfoWindow({
        content: `<div style="width: 4.8rem;
    white-space: break-spaces;
    text-align: left;">${address}</div>`,
        position: center,//显示信息窗口的坐标
        map: mapObj.value,
        offset: { x: 0, y: -20 } //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
    }
    //拨打电话
    function makePhone () {
      const phone = props.storeInfo.storeManagerMobile;
      window.location.href = `tel:${phone}`;
    }
    onMounted(() => {
      initMap();
    });
    onUpdated(() => {
      console.log("地图界面onUpdated");
      const { latitude, longitude, address } = props.storeInfo;
      setCenter(latitude, longitude, address);
    });
    return {
      mapObj,
      initMap,
      makePhone,
      setMarker,
      setInfoWindow
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../../styles/common.less";
.store-map-container {
  width: 6.5rem;
  // height: 6.35rem;
  #container {
    /*地图(容器)显示大小*/
    width: 6.5rem;
    height: 3.62rem;
  }
  .store-info {
    padding: 0.3rem;
    color: #21252e;
    .store-name {
      height: 0.32rem;
      font-size: 0.32rem;
      color: #21252e;
      letter-spacing: 0;
      line-height: 0.32rem;
      font-weight: bold;
      margin-bottom: 0.18rem;
    }
    .store-addree {
      font-size: 0.24rem;
      color: #21252e;
    }
    .make-phone {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .store-contact {
    display: flex;
    justify-content: space-between;
    margin-top: 0.1rem;
  }
}
</style>
