
import { defineComponent, onMounted, computed } from "vue";
export default {
  props: {
    inquiryInfoClientVO: {
      type: Object,
      required: true,
    },
  },
  setup() {
    function genderName(value: number) {
      if (value == 1) {
        return "男";
      } else if (value == 2) {
        return "女";
      } else {
        return "未知";
      }
    }
    return {
      genderName,
    };
  },
};
