<template>
  <!--推荐车型移除点赞功能 -->
  <div class="like-content"
       v-if="showLike">
    <div class="like-content-title">以上多份报价您还满意吗？</div>
    <div class="like-content-main">
      <div class="give-like"
           @click="giveLike">
        <img src="https://websitedn.yiautos.com/channel_quotation/v4/like.png"
             class="like-png"
             :class="likeResult ? 'img-rorate' : ''" />
        <div class="like-text">点赞</div>
        <div :class="likeResult ? 'add-one' : 'add-one-none'">+1</div>
      </div>
      <div class="give-dislike"
           @click="giveDisLike">
        <img v-if="!disLikeResult"
             src="https://websitedn.yiautos.com/channel_quotation/v4/dislike.png"
             class="like-png" />
        <img v-else
             src="https://websitedn.yiautos.com/channel_quotation/v4/re-dislike.png"
             class="like-png" />
        <div class="like-text">踩</div>
        <div :class="disLikeResult ? 'reduce-one' : 'add-one-none'">+1</div>
      </div>
    </div>
    <div class="like-gif">
      <img :class="likeResult ? 'big-like-gif' : 'opp'"
           src="https://websitedn.yiautos.com/channel_quotation/v1/give-big-like.png" />
    </div>
  </div>
</template>
<script>
import * as service from "@/api/inquiry";
import { throttle, loading } from "@/utils/Common";
export default {
  name: "like-dislike",
  props: {
    activeQuoteItem: {
      type: Object,
      required: true,
    },
    inquiryInfoClientVO: {
      type: Object,
      required: true,
    },
    showLike: {
      type: Boolean,
      required: true,
    }
  },
  data () {
    return {
      // showLike: true, //显示点赞区域
      likeResult: false, //点赞
      disLikeResult: false, //踩
      setAni: 0,
    };
  },
  methods: {
    //点赞
    giveLike () {
      this.likeResult = true;
      this.setAni = setTimeout(() => {
        this.likeResult = false;
        // this.showLike = false;
        this.$emit("changeShowLike")
        this.saveRecord(1);
      }, 1800);
    },
    //回踩
    giveDisLike () {
      this.disLikeResult = true;
      this.setAni = setTimeout(() => {
        this.likeResult = false;
        // this.showLike = false;
        this.$emit("changeShowLike")
        this.saveRecord(2);
      }, 1800);
    },
    //点赞，回踩记录
    saveRecord (data) {
      const inquiryInfoId = this.inquiryInfoClientVO.id;
      const quoteId = this.activeQuoteItem.quoteId;
      const param = {
        inquiryInfoId: inquiryInfoId, //询价信息表ID
        thumbUpStatus: data, //点赞状态 1点赞 2踩
      };
      const res = service.giveThumbUp(param);
    },
  },
  mounted () {
    this.saveRecord = throttle(this.saveRecord, 2000);
  },
  unmounted () {
    clearTimeout(this.setAni);
    this.$store.commit("setCarModel", {});
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/common.less";
.like-content {
  .flexible(column,center,center);
  width: 7.5rem;
  height: 2rem;
  background: #f4f6f8;
  position: relative;
  &-title {
    height: 0.2rem;
    background: #f4f6f8;
    font-size: 0.24rem;
    color: #a2acb1;
    margin-bottom: 0.3rem;
  }
  &-main {
    display: flex;
  }
  .like-png {
    width: 0.6rem;
    height: 0.6rem;
  }
  .like-text {
    font-size: 0.24rem;
    color: #dbdde0;
    margin-top: 0.08rem;
  }
  .give-like {
    .flexible(column,center,center);
    margin-right: 1rem;
    position: relative;
    .add-one {
      position: absolute;
      color: #ff5858;
      font-size: 0.24rem;
      top: -0.1rem;
      left: 0.6rem;
      animation-name: addOne;
      animation-duration: 1s;
      animation-delay: 1.4s;
      animation-fill-mode: forwards;
    }
  }
  .give-dislike {
    .flexible(column,center,center);
    position: relative;
    .reduce-one {
      position: absolute;
      color: #ff5858;
      font-size: 0.24rem;
      top: -0.1rem;
      left: 0.6rem;
      animation-name: addOne;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }
  .like-gif {
    position: absolute;
  }
  .add-one-none {
    display: none;
  }
  .img-rorate {
    animation-name: imgRorate;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .big-like-gif {
    animation-name: bigLike;
    animation-duration: 1s;
    //保留最后一帧，动画做完回到物体本身的状态无关
    animation-fill-mode: forwards;
  }
  .opp {
    opacity: 0;
    display: none;
    width: 1rem;
    height: 0.8rem;
  }
}
// 点赞小图旋转
@keyframes imgRorate {
  0% {
    transform: rotate(-20deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
// 点赞大图的动画
@keyframes bigLike {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  90% {
    opacity: 1;
    transform: scale(0.3, 0.3);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
// 加1的动画
@keyframes addOne {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
